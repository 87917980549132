import { NpmInstitutionalPage } from '@plurix/ecom-pages'
import React from 'react'
import { DownloadIcon } from 'src/assets/DownloadIcon'
import useCmsInstitutional from 'src/hooks/useCmsInstitutional'

import pdf from '../../../static/institucional/termos_e_condicoes_gerais_de_uso.pdf'
import { storeUrl } from '../../../store.config'
import '../../styles/pages/termos.scss'

const Terms = () => {
  const { termsBanners } = useCmsInstitutional()
  const storeUrlReplaced = storeUrl.replace('https://', '')

  return (
    <NpmInstitutionalPage
      title="Termos e Condições Gerais de Uso"
      banners={termsBanners}
    >
      <div className="terms-container">
        <p>
          Bem-vindo (a) ao{' '}
          <a href={storeUrl} target="_blank" rel="noreferrer">
            {storeUrlReplaced}
          </a>
          !
          <br />
          Apresentamos a você, usuário, os Termos e Condições de Uso, onde
          constam as principais regras a serem observadas por todos que acessam
          a plataforma digital do Superpão e suas funcionalidades.
        </p>
        <p>
          A plataforma digital “{storeUrlReplaced}”, disponibilizada e mantida
          SUPERMERCADO SUPERPÃO Ltda., com sede no Estado do Paraná/PR, na
          Cidade de Guarapuava, na Rua Guaíra, nº 55555, Boqueirão, CEP:
          85020-000, inscrito no CNPJ/MF sob o nº 77883320/0001-61, (“Lojista”),
          disponibiliza serviço de E-commerce convencional. Para melhor
          compreensão, vamos explicar o que significa:
        </p>
        <p>
          <strong>“E-commerce convencional”:</strong> venda e entrega realizada
          diretamente pelo Superpão, o qual dispõe de estoque próprio. Neste
          caso, abaixo do produto estará informado que o produto será vendido e
          entregue por {storeUrlReplaced}.
          <br />
          Ressaltamos que o E-commerce é destinado à venda de produtos e
          prestação de serviços diversos pela internet, sendo certo o nosso
          compromisso de buscar qualidade e segurança em todas as operações
          realizadas por meio da nossa plataforma.
          <br />
          Como condição para acesso e utilização da plataforma{' '}
          <a href={storeUrl} target="_blank" rel="noreferrer">
            {storeUrlReplaced}
          </a>
          , esperamos que <b>USUÁRIO</b> faça a leitura completa e atenta das
          regras deste documento e das demais políticas (Política de
          Privacidade,Termos de uso e Cookies), estando plenamente ciente e de
          acordo com elas.
          <br />
          Caso o <b>USUÁRIO</b> não concorde com referidas condições, poderá
          abster-se de consentir, por meio da não utilização da plataforma.
          Todavia, caso o USUÁRIO decida prosseguir com sua utilização,
          presumimos o seu consentimento e aceite às condições apresentadas
          neste documento.
        </p>
        <p>
          <a
            href={pdf}
            download="termos_e_condicoes_gerais_de_uso.pdf"
            type="application/pdf"
          >
            <DownloadIcon />
            Baixar Documento Completo
          </a>
        </p>
      </div>
    </NpmInstitutionalPage>
  )
}

export default Terms
